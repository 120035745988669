import React from 'react';
import { Link } from 'gatsby';
import Leaflet from 'leaflet'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'

import 'leaflet/dist/leaflet.css';
import './heatMap.css';
// @ts-ignore
import icon from 'leaflet/dist/images/marker-icon.png';
// @ts-ignore
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { ServiceIcon } from './serviceIcon';
import { VerifiedDisplay } from './verifiedDisplay';

//Only run this when this is being rendered in browser - messes with Gatsby's SSR
if (typeof(window) !== 'undefined') {
  let DefaultIcon = Leaflet.icon({
      iconUrl: icon,
      shadowUrl: iconShadow
  });

  Leaflet.Marker.prototype.options.icon = DefaultIcon;
}

/**
 * Component to display an embedded Leaflet.js Map (saves cost) of a list of locations, displayed as markers on the map. Should zoom to the area that all the locations are in.
 * @param {*} props Requires either the geolocationsList prop - expects a list of 2-item lists - so [[lat1, long1], [lat2, long2], etc..]
 * @author BrianCastor
 */
export default function HeatMap(props) {
    const geolocationsList = props.geolocationsList;

    if (geolocationsList.length === 0) {
        return null;
    }

    const justLocations = geolocationsList.map(loc => [loc.latitude, loc.longitude])

    return (<MapContainer bounds={justLocations} scrollWheelZoom={false}>
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {geolocationsList.map((location, index) => {
          return (
          <Marker position={[location.latitude,location.longitude]} key={index}>
            <Popup>
              <div><strong>{location.label}</strong></div>
              <div>
                <VerifiedDisplay dateVerified={location.Date_Verified_Services__c} />
              </div>
              <div style={{marginBottom:'5px'}}>
                <span>Free / Discounted Services:</span>
                <br/>
                {location.services.map(service => {
                  return (<ServiceIcon service={service}/>)
                })}
              </div>
              <Link to="/signup">Sign up to learn more!</Link>
            </Popup>
          </Marker>
          )
        })}              
      </MapContainer>)
}