import React from "react"
import { useState, useEffect } from "react"
import { Link } from "gatsby"
import Leaflet from "leaflet"
import { OpenStreetMapProvider } from "leaflet-geosearch"
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet"

import { getDistance } from "../../utils"
import axios from "axios"

import { Unavailable } from "./unavailable"
import { LoadingDisplay } from "../loadingDisplay"

import "leaflet/dist/leaflet.css"
import "./heatMap.css"
// @ts-ignore
import icon from "leaflet/dist/images/marker-icon.png"
// @ts-ignore
import iconShadow from "leaflet/dist/images/marker-shadow.png"
import { ServiceIcon } from "./serviceIcon"
import { VerifiedDisplay } from "./verifiedDisplay"
import {
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import SearchIcon from "@material-ui/icons/Search"

//Only run this when this is being rendered in browser - messes with Gatsby's SSR
if (typeof window !== "undefined") {
  let DefaultIcon = Leaflet.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
  })

  Leaflet.Marker.prototype.options.icon = DefaultIcon
}

/**
 * Component to display an embedded Leaflet.js Map (saves cost) of a list of locations, displayed as markers on the map. Should zoom to the area that all the locations are in.
 * @param {*} props Requires either the geolocationsList prop - expects a list of 2-item lists - so [[lat1, long1], [lat2, long2], etc..]
 * @author BrianCastor
 */
export default function HeatMap2(props) {
  const geolocationsList = props.geolocationsList
  const [userLocation, setUserLocation] = useState(null)
  const [filteredLocations, setFilteredLocations] = useState([])
  const [searchAddress, setSearchAddress] = useState("")
  const [options, setOptions] = useState([])
  const [coords, setCoords] = useState(null)
  const [resultFound, setResultFound] = useState(false)
  const [searchBarLoading, setSearchBarLoading] = useState(false)
  const [mapLoading, setMapLoading] = useState(true)
  const provider = new OpenStreetMapProvider()
  //maximum search radius
  const maxDistance = 10

  //Attempt to get location data from browser on first page load
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        let userLoc = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        }
        handleUserLocationChange(userLoc)
      })
    }
    setMapLoading(false)
  }, [])

  const handleUserLocationChange = async userLoc => {
    const newLocations = await geolocationsList.filter(
      loc => getDistance(loc, userLoc) < maxDistance
    )

    setFilteredLocations(newLocations)
    setUserLocation(userLoc)

    if (newLocations.length > 0) {
      setResultFound(true)
    } else {
      setResultFound(false)
    }
  }

  //API call for autocompletion of search bar
  const handleSearchInput = async address => {
    setSearchBarLoading(true)
    setSearchAddress(address)
    if (address.length > 1) {
      await axios
        .get(
          `https://nominatim.openstreetmap.org/search?format=geojson&limit=10&q=${address}&countrycodes=us`
        )
        .then(response => {
          let strings = []
          let coords = new Map()

          response.data.features.forEach(result => {
            const {
              geometry: { coordinates },
              properties: { display_name },
            } = result
            strings.push(display_name)
            coords[display_name] = {
              longitude: coordinates[0],
              latitude: coordinates[1],
            }
          })

          setOptions(strings)
          setCoords(coords)
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => setSearchBarLoading(false))
    } else if (address.length == 0) {
      setOptions([])
      setCoords(null)
    }
  }

  //Attempt to retrieve search result from autofill and use search provider as a backup
  const handleSearchSubmit = async () => {
    setMapLoading(true)
    if (searchAddress && coords[searchAddress]) {
      handleUserLocationChange(coords[searchAddress])
    } else {
      const results = await provider.search({ query: searchAddress })
      if (results.length == 0) {
        setResultFound(false)
        setUserLocation(true)
      } else {
        handleUserLocationChange({
          longitude: results[0].x,
          latitude: results[0].y,
        })
      }
    }
    setMapLoading(false)
  }

  //Helper function that uses the useMap hook to update the leaflet map when the user searches for a new location
  const MapController = props => {
    const map = useMap()
    useEffect(() => {
      if (props.userLocation) {
        map.setView(
          [props.userLocation.latitude, props.userLocation.longitude],
          12
        )
      }
    }, [props.userLocation])
    return null
  }

  return (
    <div>
      {/* Search Bar - Chore: refactor out into its own component */}
      <h4>Search for Services Nearby</h4>
      <Grid container spacing={0} justify="center">
        <Grid item xs={12}>
          <Autocomplete
            id="geo-search"
            loading={searchBarLoading}
            options={options}
            color="primary"
            size="small"
            onChange={(event, value, reason) => {
              if (reason == "select-option") {
                setSearchAddress(value)
              }
            }}
            freeSolo
            autoComplete
            filterOptions={x => x}
            clearOnEscape
            renderInput={params => (
              <TextField
                {...params}
                placeholder="Address"
                variant="outlined"
                color="primary"
                value={searchAddress}
                onKeyPress={event => {
                  if (event.key === "Enter") {
                    handleSearchSubmit()
                  }
                }}
                onChange={event => handleSearchInput(event.target.value)}
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => handleSearchSubmit()}
                        edge="end"
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container spacing={0} justify="center">
        {/* Spinning loading indicator */}
        {mapLoading && <LoadingDisplay />}

        {/* Services unavailable message */}
        {userLocation && !resultFound && !mapLoading && <Unavailable />}
      </Grid>

      <div>
        {/* Map view only displayed when results are found */}
        {userLocation && resultFound && !mapLoading && (
          <MapContainer
            center={[userLocation.latitude, userLocation.longitude]}
            zoom={13}
            scrollWheelZoom={false}
          >
            <MapController userLocation={userLocation} />
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker
              position={[userLocation.latitude, userLocation.longitude]}
              key={"user"}
            >
              <Popup>
                <div>
                  <strong>You are Here</strong>
                </div>
              </Popup>
            </Marker>
            {filteredLocations.map((location, index) => {
              return (
                <Marker
                  position={[location.latitude, location.longitude]}
                  key={index}
                >
                  <Popup>
                    <div>
                      <strong>{location.label}</strong>
                    </div>
                    <div>
                      <VerifiedDisplay
                        dateVerified={location.Date_Verified_Services__c}
                      />
                    </div>
                    <div style={{ marginBottom: "5px" }}>
                      <span>Free / Discounted Services:</span>
                      <br />
                      {location.services.map(service => {
                        return <ServiceIcon service={service} />
                      })}
                    </div>
                    <Link to="/signup">Sign up to learn more!</Link>
                  </Popup>
                </Marker>
              )
            })}
          </MapContainer>
        )}
      </div>
    </div>
  )
}
