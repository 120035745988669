import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import UnauthenticatedHomePage from "../components/unauthenticatedHomePage"

const IndexPage = (data) => (
  <Layout>
    <Seo title="Welcome" />
    <UnauthenticatedHomePage />
  </Layout>
)

export default IndexPage;