import React from 'react';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';

/**
 * Displays a label for an Account
 * @param {*} props 
 * @author BrianCastor
 */
export const AccountLabel = props => {
    return (
        <div style={{ color: 'rgba(0, 0, 0, 0.54)', marginBottom: '5px'}}>
            <BusinessOutlinedIcon style={{ fontSize: '15px', verticalAlign: 'middle', paddingRight: '5px' }} />
            <span style={{ verticalAlign: 'middle', fontSize: '13px' }}>SERVICE PROVIDER</span>
        </div>
    )
}