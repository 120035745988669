import React from 'react';

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";

import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';

import { VerifiedDisplay } from '../cardComponents/verifiedDisplay';
import { PostingLabel } from './postingLabel';

import Button from '@material-ui/core/Button';
import { Link } from 'gatsby';

/**
 * Abbreviated detail 'card' display for key Posting information. Used on unauthenticated homepage
 * @param {*} props 
 * @author BrianCastor
 */
export default function PostingCard(props) {
    const posting = props.posting;

    if (!posting) {
        return null;
    }

    return (
        <Card style={{fontSize: '12px' }}>
            <CardActionArea component={Link} to="/signup" >
                <CardContent>
                    <PostingLabel/>
                    <div style={{ fontSize: '15px' }}>
                        <span>{posting.Drupal_HOS_Post_Title__c}</span>
                    </div>
                    <div style={{ fontSize: '12px' }}>
                        <VerifiedDisplay dateVerified={posting.Original_Post_Date__c} />
                    </div>
                    <div>
                        <BusinessOutlinedIcon style={{ fontSize: '15px', verticalAlign: 'middle', paddingRight: '5px' }} />
                        {posting.AccountId__r ? posting.AccountId__r.Name : (<span>No service provider information given</span>)}
                    </div>
                </CardContent>
            </CardActionArea>
            <CardActions>
            <Button component={Link} to="/signup" size="small" style={{color:'black'}}>Learn More</Button>
            </CardActions>
        </Card>
    )
};