import React from "react"
import NotListedLocationIcon from "@material-ui/icons/NotListedLocation"
import { Button, Grid, Paper, TextField, Typography } from "@material-ui/core"
import { Link } from "gatsby"

/**
 * Generic error component to display when we hit an error
 * @param {*} props
 * @author BrianCastor
 */
export const Unavailable = props => {
  return (
    <Paper style={{ marginTop: "20px" }}>
      <Grid container justify="center">
        <Grid item>
          <NotListedLocationIcon style={{ fontSize: 100, color: "pink" }} />
        </Grid>
        <Grid item xs={10}>
          <Typography align="justify" variant="h6">
            We're having trouble finding verified resources nearby. You can try
            searching in another area, or sign up below and we will let you know
            when we verify service providers near you.
          </Typography>
        </Grid>
      </Grid>
      <Grid justify="center" container>
        <Grid item xs={4} md={2}>
          <Button
            fullWidth
            style={{ marginTop: "10px", marginBottom: "10px" }}
            color="primary"
            variant="contained"
            component={Link}
            to="/signup"
          >
            Sign Up
          </Button>
        </Grid>
      </Grid>
    </Paper>
  )
}
