import React from 'react';

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";

import { VerifiedDisplay } from '../cardComponents/verifiedDisplay';
import { AccountLabel } from './accountLabel';
import Button from '@material-ui/core/Button';
import { Link } from 'gatsby';

/**
 * Abbreviated detail 'card' display for key Account information. Used on unauthenticated homepage
 * @param {*} props 
 * @author BrianCastor
 */
export default function AccountCard(props) {
    const account = props.account;

    if (!account) {
        return null;
    }

    return (
    <Card style={{fontSize:'12px'}} >
        <CardActionArea component={Link} to="/signup" >
        <CardContent>
        <AccountLabel/>
        <div style={{fontSize:'15px'}}>
            <span>{account.Name}</span>
        </div>
        <div style={{fontSize:'12px'}}>
        <VerifiedDisplay dateVerified={account.Date_Verified_Services__c}/>
        </div>
        <div>
            {account.ShippingAddress ? account.ShippingAddress.state : ''}
        </div>
        </CardContent>
        </CardActionArea>
        <CardActions>
            <Button component={Link} to="/signup" size="small" style={{color:'black'}}>Learn More</Button>
        </CardActions>
    </Card>
    )
};