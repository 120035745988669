import React, { useContext } from 'react';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import Skeleton from '@material-ui/lab/Skeleton';

import AccountCard from './accounts/accountCard';

import { globalContext } from '../GlobalContext';

import { Link } from 'gatsby';
import PostingCard from './postings/postingCard';
import API from '../API';
import  HeatMap from './cardComponents/heatMap';
import HeatMap2 from './cardComponents/heatMapv2';
//Need to selectively import only if window's - leaflet.js (included in HeatMap component) does not play well with server side rendering

/**
 * The 'home' page for users who haven't authenticated yet (first time visiting site for instance). Shows an overview of what HOS does, and some current service providers
 * @param {*} props 
 * @author BrianCastor
 */
export default function UnauthenticatedHomePage(props) {
  const context = useContext(globalContext);
  const [recentPostings, setRecentPostings] = React.useState([]);
  const [recentPostingsError, setRecentPostingsError] = React.useState(null);
  const [recentAccounts, setRecentAccounts] = React.useState([]);
  const [recentAccountsError, setRecentAccountsError] = React.useState(null);
  const [totalAccountsAndPostings, setTotalAccountsAndPostings] = React.useState(null);
  const [geolocations, setGeolocations] = React.useState([]);

  React.useEffect(() => {
    //Get Recent Postings
    API.getRecentPostings().then(data => {
      setRecentPostings(data);
    }).catch(err => {
      setRecentPostingsError(err.message);
    });

    //Get Recent Accounts
    API.getRecentAccounts().then(data => {
      setRecentAccounts(data);
    }).catch(err => {
      setRecentAccountsError(err.message);
    });

    //Get stats
    API.getStatistics().then(data => {
      setTotalAccountsAndPostings(data);
    }).catch(err => {
      console.log(err);
    });

    //Get stats
    API.getGeolocations().then(data => {
      setGeolocations(data);
    }).catch(err => {
      console.log(err);
    });
  }, []);

  //Redirect to /home if user is signed in
  if (context.isRegistered()) {
    context.navigateSafe('/home');
    return (null);
  }

  return (
    <div>
      <div style={{ minHeight: "425px" }}>
        <Container maxWidth="md" style={{ paddingTop: "60px" }}>
          <Grid container>
            <Grid item xs={12} md={8}>
              <div style={{ maxWidth: "600px" }}>
                <h1 style={{ color: "white", fontSize: "40px" }}>
                  Relevant, verified services in your area
                </h1>
                <h3
                  style={{
                    color: "white",
                    fontWeight: "lighter",
                    fontSize: "23px",
                  }}
                >
                  Sign up to receive a personalized list of nearby
                  HopeOneSource-verified services, and to remain updated with
                  text messages.
                </h3>
              </div>
              <Button
                color="primary"
                variant="contained"
                size="large"
                component={Link}
                to="/signup"
                style={{
                  fontWeight: "bold",
                  marginTop: "20px",
                  fontSize: "18px",
                }}
              >
                Connect to Services
              </Button>
            </Grid>
            <Grid item xs={12} style={{ height: "125px" }}></Grid>

            <Grid item xs={12}>
                {/*'Heat Map' of locations of service providers/services we have info on*/}
                {geolocations.length > 0 && typeof window !== "undefined" && (
                  <HeatMap2 geolocationsList={geolocations} />
                )}
            </Grid>
          </Grid>
        </Container>
      </div>
      <div>
        <Container maxWidth="md" style={{ paddingTop: "15px" }}>
          <Grid item xs={12}>
            <Paper elevation={2} style={{ padding: "15px" }}>
              <div
                style={{
                  display: "inline-block",
                  paddingRight: "10px",
                  verticalAlign: "top",
                }}
              >
                <VerifiedUserOutlinedIcon
                  style={{ fontSize: "60px", color: "#66b2e5" }}
                />
              </div>
              <div style={{ display: "inline-block", verticalAlign: "top" }}>
                {totalAccountsAndPostings ? (
                  <div style={{ fontWeight: "bold", fontSize: "30px" }}>
                    {totalAccountsAndPostings}
                  </div>
                ) : (
                  <Skeleton variant="rect" height={35} width={80} />
                )}
                <div style={{ marginTop: "2px" }}>
                  Services Verified Recently
                </div>
              </div>
            </Paper>
          </Grid>

          <h4>Recent Service Providers</h4>
          <p style={{ color: "red" }}>{recentAccountsError}</p>
          <Grid
            container
            spacing={3}
            direction="row"
            alignItems="flex-start"
            justify="space-around"
          >
            {recentAccounts.map((account, index) => {
              return (
                <Grid key={index} item md={4} sm={6} xs={12}>
                  <AccountCard account={account} />
                </Grid>
              )
            })}
          </Grid>
        </Container>

        <Container
          maxWidth="md"
          style={{ paddingTop: "15px", paddingBottom: "100px" }}
        >
          <h4>Recent Postings</h4>
          <p style={{ color: "red" }}>{recentPostingsError}</p>
          <Grid
            container
            spacing={3}
            direction="row"
            alignItems="flex-start"
            justify="space-around"
          >
            {recentPostings.map((posting, index) => {
              return (
                <Grid key={index} item md={4} sm={6} xs={12}>
                  <PostingCard posting={posting} />
                </Grid>
              )
            })}
          </Grid>
        </Container>
      </div>
    </div>
  )
}